import React from "react"
import { graphql } from "gatsby"

import { useAnalytics } from "../hooks"

import { courseGroupType, courseBookingType, courseType } from "../types"
import { CourseGroupPage } from "../components"
import { courseTopicSlug } from "../utilities"

interface Props {
  data: { contentfulCourseGroups: courseGroupType } & {
    allContentfulCourseBookings: {
      edges: Array<{
        node: courseBookingType
      }>
    }
  } & {
    allContentfulCourse: {
      edges: Array<{
        node: courseType
      }>
    }
  }
}

export default ({ data }: Props) => {
  const isCoursesForStudents =
    data.contentfulCourseGroups.contentful_id === "1p32s6m8Q4hUT63uUIrEf3"

  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: courseTopicSlug(data.contentfulCourseGroups.slug),
  })

  const url =
    process.env.GATSBY_SITE_URL +
    courseTopicSlug(data.contentfulCourseGroups.slug)

  const getAllCoursesWithoutNestedNodeObject = (
    courses: { node: courseType }[]
  ) => {
    const allCourses: courseType[] = []

    courses.forEach(({ node }) => allCourses.push(node))

    return allCourses
  }

  const getBookingsMatchingCourse = (
    bookings: { node: courseBookingType }[]
  ) => {
    let bookingsMatchingCourses: { node: courseBookingType }[] = []

    if (isCoursesForStudents) {
      const studentBookings = bookings.filter(
        (booking) => booking.node.forStudents
      )

      const allStudentCoursesWithoutNestedNodeObject =
        getAllCoursesWithoutNestedNodeObject(data.allContentfulCourse.edges)

      studentBookings.forEach((booking) => {
        booking.node.courses.forEach((course) => {
          if (
            allStudentCoursesWithoutNestedNodeObject &&
            allStudentCoursesWithoutNestedNodeObject.some(
              (filteredCourse) =>
                filteredCourse.contentful_id === course.contentful_id
            )
          ) {
            if (
              bookingsMatchingCourses.some(
                (bookingsMatchingCourse) =>
                  bookingsMatchingCourse.node.contentful_id ===
                  booking.node.contentful_id
              )
            ) {
              // booking already in bookingsMatchingCourses
            } else {
              bookingsMatchingCourses.push(booking)
            }
          }
        })
      })
    } else {
      bookings.forEach((booking) => {
        booking.node.courses.forEach((course) => {
          if (
            data.contentfulCourseGroups.courses &&
            data.contentfulCourseGroups.courses.some(
              (filteredCourse) =>
                filteredCourse.contentful_id === course.contentful_id
            )
          ) {
            if (
              bookingsMatchingCourses.some(
                (bookingsMatchingCourse) =>
                  bookingsMatchingCourse.node.contentful_id ===
                  booking.node.contentful_id
              )
            ) {
              // booking already in bookingsMatchingCourses
            } else {
              bookingsMatchingCourses.push(booking)
            }
          }
        })
      })
    }

    return bookingsMatchingCourses
  }

  const bookingsMatchingCourseGroup = getBookingsMatchingCourse(
    data.allContentfulCourseBookings.edges
  )
  return (
    <CourseGroupPage
      courseGroup={data.contentfulCourseGroups}
      bookings={bookingsMatchingCourseGroup}
      url={url}
      variant="topic"
      viewCoursesAlternateUrl={
        data.contentfulCourseGroups.viewCoursesByLocationUrl
      }
      allCourses={
        isCoursesForStudents
          ? getAllCoursesWithoutNestedNodeObject(data.allContentfulCourse.edges)
          : undefined
      }
      testimonials={data.contentfulCourseGroups.testimonials}
      isCoursesForStudents={isCoursesForStudents}
      pageSections={data.contentfulCourseGroups.pageSections}
    />
  )
}

export const pageQuery = graphql`
  query CourseGroup($contentful_id: String!) {
    contentfulCourseGroups(contentful_id: { eq: $contentful_id }) {
      contentful_id
      name
      slug
      image {
        gatsbyImageData
        url
        title
      }
      headerVideo {
        url
        title
      }
      description {
        raw
      }
      viewCoursesByLocationUrl
      hideProfessionFilters
      courses {
        contentful_id
        cpdTime
        hideCoursePrice
        skillLevel
        summary {
          summary
        }
        slug
        onlineOnly
        name
        partnerLogo {
          gatsbyImageData
          title
        }
        thinkificTraining {
          courseUrl
          contentful_id
          duration
        }
        professions
        topic {
          slug
        }
      }
      testimonials {
        contentful_id
        name
        position
        testimonial {
          testimonial
        }
        photo {
          gatsbyImageData
          title
        }
      }
      pageSections {
        ... on ContentfulPageSectionsTextWithImage {
          __typename
          id
          contentful_id
          image {
            gatsbyImageData
            filename
            url
            title
          }
          video {
            url
            title
          }
          imageAlignment
          heading
          content {
            raw
          }
          backgroundColour
        }
        ... on ContentfulPageSectionsTextWithLogoCloud {
          __typename
          id
          contentful_id
          heading
          logos {
            title
            gatsbyImageData
          }
          content {
            raw
          }
          backgroundColour
        }
      }
      faqs {
        heading
        content {
          raw
        }
        contentful_id
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        description {
          description
        }
        title
      }
    }
    allContentfulCourseBookings(
      sort: { order: ASC, fields: startDate }
      filter: { contentful_id: { ne: "3bBK7TnvlCI8JsnjF9Ma1m" } }
    ) {
      edges {
        node {
          contentful_id
          addressFull
          addressShort
          endDate
          startDate
          stripePriceId
          priceCurrency
          price
          showVat
          priceDisplayed
          forStudents
          location {
            contentful_id
            city {
              name
              contentful_id
              region {
                name
                contentful_id
              }
            }
          }
          courses {
            contentful_id
          }
        }
      }
    }
    allContentfulCourse(
      filter: {
        course___bookings: { elemMatch: { forStudents: { eq: true } } }
      }
    ) {
      edges {
        node {
          contentful_id
          cpdTime
          hideCoursePrice
          skillLevel
          summary {
            summary
          }
          slug
          onlineOnly
          name
          partnerLogo {
            gatsbyImageData
            title
          }
          thinkificTraining {
            courseUrl
            contentful_id
            duration
          }
          professions
          topic {
            slug
          }
        }
      }
    }
  }
`
